import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { postCertificateMassive } from '../../api/MassiveDataService';
import { useDropzone } from 'react-dropzone';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';



const ExcelUpload = ({ id, onDataParsed }) => {
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState([]);
    const [idItem, setIdItem] = useState(id);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        setIdItem(id);
    }, [id]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        maxFiles: 1, // Permitir solo un archivo
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                setFileName(selectedFile.name); // Guardar el nombre del archivo
                handleFileUpload({ target: { files: acceptedFiles } });
            }
        },
    });

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheet = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheet];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 0) {
                const headerRow = jsonData[0];
                const dataRows = jsonData.slice(1);
                setHeaders(headerRow);

                const mappedData = dataRows
                    .map(row => headerRow.reduce((acc, header, index) => {
                        acc[header] = row[index] || '';
                        return acc;
                    }, {}))
                    .filter(row => Object.values(row).some(value => value.trim() !== ''));

                setData(mappedData);
                console.log("Data: ", mappedData);
                onDataParsed(mappedData);

            }
        };
        reader.readAsBinaryString(selectedFile);
        console.log("Archivo subido");
    };



    return (
        <div className="mt-12">
            <div
                {...getRootProps({
                    className: `dropzone border rounded p-4 ${isDragActive ? 'bg-light' : 'bg-white'
                        }`
                })}
            >
                <input {...getInputProps()} />
                <p className="text-center">
                    {isDragActive
                        ? 'Suelta el archivo aquí...'
                        : 'Suelta el archivo Excel o da clic para seleccionar'}
                </p>
                {fileName && <p className="text-center mt-2">Archivo seleccionado: <strong>{fileName}</strong></p>}
            </div>
            {/* <div className="mt-3">
                <button
                    type="button"
                    className="btn btn-primary w-100"
                >
                    Subir
                </button>
            </div> */}
        </div>
    );
};

export default ExcelUpload;
