import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { attachCertificate, attachPolicy } from '../api/InsuredService';
import { getCertificatesBySubgroup } from '../api/CertificateService';
import { getSubgroupByPolicyId } from '../api/SubgroupService';
import { initialInsuredData } from "../components/Helpers/initialData";

const InsuredForm = ({ showModal, policyForeign, onClose, policyData }) => {
  const MySwal = withReactContent(Swal);
  const [subgroups, setSubgroups] = useState([]);
  const [selectedSubgroup, setSelectedSubgroup] = useState('');
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState('');

  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(initialInsuredData);


  useEffect(() => {
    if (policyData.policy_type.toLowerCase().includes('individual')) {
      setType('individual')
    } else {
      setType('colectivo')
    }
    if (showModal && policyForeign && type != 'individual') {
      fetchSubgroups();
    }
    console.log("policyData", policyData)
  }, [policyForeign, showModal]);

  const fetchSubgroups = async () => {
    try {
      const data = await getSubgroupByPolicyId(policyForeign);
      setSubgroups(data);
    } catch (error) {
      console.error('Error fetching Subgroups:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubgroupChange = (event) => {
    const subgroupId = event.target.value;
    setSelectedSubgroup(subgroupId);
    if (subgroupId) {
      setFormData({ ...formData, subgroup_id: subgroupId });
      fetchCertificates(subgroupId);
    }
  };

  const fetchCertificates = async (subgroupId) => {
    try {
      const data = await getCertificatesBySubgroup(subgroupId);
      setCertificates(data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  const handleCertificatesChange = (event) => {
    const certificateId = event.target.value;
    setSelectedCertificate(certificateId);
    if (certificateId) {
      setFormData({ ...formData, certificate_id: certificateId });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true); // Iniciar estado de carga

    try {
      if (type === 'individual') {
        await attachPolicy(policyData.id_policy, formData);
        await MySwal.fire({
          title: '¡Éxito!',
          text: 'La póliza se ha asociado correctamente. ¿Quieres añadir otro dependiente al certificado?',
          showCancelButton: true,
          confirmButtonText: 'Sí, añadir otro dependiente',
          cancelButtonText: 'No, cerrar',
          icon: 'success',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setFormData(initialInsuredData);
          } else {
            setFormData(initialInsuredData);
            onClose();
          }
        });
      } else {
        await attachCertificate(selectedCertificate, formData);
        await MySwal.fire({
          title: '¡Éxito!',
          text: 'El certificado se ha asociado correctamente al dependiente. ¿Quieres añadir otro dependiente al certificado?',
          showCancelButton: true,
          confirmButtonText: 'Sí, añadir otro dependiente',
          cancelButtonText: 'No, cerrar',
          icon: 'success',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setFormData(initialInsuredData);
          } else {
            setFormData(initialInsuredData);
            onClose();
          }
        });
      }
    } catch (error) {
      await MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar la póliza.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    } finally {
      setLoading(false); // Finalizar estado de carga
    }
  };


  return (
    <Modal dialogClassName="modal-xl" show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Asegurado y asociarlo al certificado o Poliza </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <h5>Datos Requeridos</h5>
          {type == 'colectivo' && (
            <div className="row">
              <div className="form-floating mb-3 col-6">
                <select
                  name="subgroup_id"
                  value={selectedSubgroup}
                  onChange={handleSubgroupChange}
                  className="form-control"
                  id="subgroup_id"
                  required
                >
                  <option value="">Selecciona un Subgrupo</option>
                  {subgroups.map(subgroup => (
                    <option key={subgroup.id_subgroup} value={subgroup.id_subgroup}>
                      {subgroup.subgroup_number} - {subgroup.subgroup_name}
                    </option>
                  ))}
                </select>
                <label htmlFor="subgroup_id">Subgrupo</label>
              </div>

              <div className="form-floating mb-3 col-6">
                <select
                  name="certificate_id"
                  value={selectedCertificate}
                  onChange={handleCertificatesChange}
                  className="form-control"
                  id="certificate_id"
                  required
                >
                  <option value="">Selecciona un Certificado</option>
                  {certificates.map(certificate => (
                    <option key={certificate.id_certificate} value={certificate.id_certificate}>
                      {certificate.certificate_number}
                    </option>
                  ))}
                </select>
                <label htmlFor="certificate_id">Certificados</label>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Primer nombre"
                  required
                />
                <label htmlFor="first_name" className="form-label">
                  Primer nombre
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="last_name_paternal"
                  name="last_name_paternal"
                  value={formData.last_name_paternal}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Apellido Paterno"
                  required
                />
                <label htmlFor="last_name_paternal" className="form-label">
                  Apellido Paterno
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="last_name_maternal"
                  name="last_name_maternal"
                  value={formData.last_name_maternal}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Apellido Materno"
                  required
                />
                <label htmlFor="last_name_maternal" className="form-label">
                  Apellido Materno
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <select
                  name="relationship"
                  value={formData.relationship}
                  onChange={handleChange}
                  className="form-select"
                  placeholder="Relación"
                  required
                >
                  <option value=''>Relación</option>
                  <option value='holder'>Titular</option>
                  <option value='spouse'>Conyuge</option>
                  <option value='dependent'>Dependiente</option>
                </select>
                <label htmlFor="relationship" className="form-label">
                  Relación
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="date_birth"
                  name="date_birth"
                  value={formData.date_birth}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                />
                <label htmlFor="date_birth" className="form-label">
                  Fecha de Nacimiento
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-select"
                  placeholder="Género"
                  required
                >
                  <option value=''>Género</option>
                  <option value='M'>Masculino</option>
                  <option value='F'>Femenino</option>
                </select>
                <label htmlFor="gender" className="form-label">
                  Género
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="current_antiquity"
                  name="current_antiquity"
                  value={formData.current_antiquity}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Antigüedad Actual"
                />
                <label htmlFor="current_antiquity" className="form-label">
                  Antigüedad Actual
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="total_antiquity"
                  name="total_antiquity"
                  value={formData.total_antiquity}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Antigüedad en otras aseguradoras"
                />
                <label htmlFor="total_antiquity" className="form-label">
                  Antigüedad en otras aseguradoras
                </label>
              </div>
            </div>
          </div>
          <Accordion className='mb-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Datos Extras</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {/* <div className="col-md-4">
                      <div className="form-floating mb-2">
                        <input
                          type="email"
                          id="work_email"
                          name="work_email"
                          value={formData.work_email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Email del Trabajo"
                        />
                        <label htmlFor="work_email" className="form-label">
                          Email del Trabajo
                        </label>
                      </div>
                    </div> */}
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="rfc"
                        name="rfc"
                        value={formData.rfc}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="RFC"
                      />
                      <label htmlFor="rfc" className="form-label">
                        RFC
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="curp"
                        name="curp"
                        value={formData.curp}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="CURP"
                      />
                      <label htmlFor="curp" className="form-label">
                        CURP
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          id="phone_office"
                          name="phone_office"
                          value={formData.phone_office}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Teléfono de Oficina"
                        />
                        <label htmlFor="phone_office" className="form-label">
                          Teléfono de Oficina
                        </label>
                      </div>
                    </div> */}
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="phone_personal"
                        name="phone_personal"
                        value={formData.phone_personal}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Teléfono Personal"
                      />
                      <label htmlFor="phone_personal" className="form-label">
                        Teléfono Personal
                      </label>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="d-flex justify-content-between">
            <Button variant="danger" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {' Cargando...'}
                </>
              ) : 'Crear'}
            </Button>


          </div>

        </Form>
      </Modal.Body>

    </Modal>

  );
};

export default InsuredForm;