import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateInsured } from '../../../api/InsuredService';

const ModalEditInsured = ({ showModal, handleClose, insured, updateList }) => {
    console.log('asegurado seleccionado', insured)
    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = React.useState(insured);

    React.useEffect(() => {
        setFormData(insured);
    }, [insured]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateInsured(insured.id_insured, formData)
            MySwal.fire({
                title: '¡Éxito!',
                text: 'El email se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            }).then (()=>{
                updateList()
            });
            handleClose();
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el email.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
            });
        }
    };

    return (
        <Modal size="lg" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar asegurado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-center fw-bold text-uppercase">
                    Actualizar a nivel usuario esta en pruebas
                </p>
                <Form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <Form.Group controlId="formFirstName">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        onChange={handleChange}
                                        value={formData.first_name || ""}
                                        
                                    />
                                    <label htmlFor="first_name">Nombre:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formLastNamePaternal">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="last_name_paternal"
                                        onChange={handleChange}
                                        value={formData.last_name_paternal || ""}
                                        
                                    />
                                    <label htmlFor="last_name_paternal">Apellido Paterno:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formLastNameMaternal">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="last_name_maternal"
                                        onChange={handleChange}
                                        value={formData.last_name_maternal || ""}
                                        
                                    />
                                    <label htmlFor="last_name_maternal">Apellido Materno:</label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <Form.Group controlId="formRFC">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="rfc"
                                        onChange={handleChange}
                                        value={formData.rfc || ""}
                                        
                                    />
                                    <label htmlFor="rfc">RFC:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formCURP">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="curp"
                                        onChange={handleChange}
                                        value={formData.curp || ""}
                                        
                                    />
                                    <label htmlFor="curp">CURP:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formPhoneOffice">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="phone_office"
                                        onChange={handleChange}
                                        value={formData.phone_office || ""}
                                        
                                    />
                                    <label htmlFor="phone_office">Teléfono de Oficina:</label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <Form.Group controlId="formPhonePersonal">
                                <div className="form-floating">
                                    <Form.Control
                                        type="text"
                                        name="phone_personal"
                                        onChange={handleChange}
                                        value={formData.phone_personal || ""}
                                        
                                    />
                                    <label htmlFor="phone_personal">Teléfono Personal:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formRelationship">
                                <div className="form-floating">
                                    <select
                                        name="relationship"
                                        onChange={handleChange}
                                        className="form-select"
                                        value={formData.relationship || ""}
                                        
                                    >
                                        <option value="">Selecciona la Relación</option>
                                        <option value="holder">Titular</option>
                                        <option value="spouse">Cónyuge</option>
                                        <option value="dependent">Dependiente</option>
                                    </select>
                                    <label htmlFor="relationship">Relación:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formDateBirth">
                                <div className="form-floating">
                                    <Form.Control
                                        type="date"
                                        name="date_birth"
                                        onChange={handleChange}
                                        value={formData.date_birth || ""}
                                        
                                    />
                                    <label htmlFor="date_birth">Fecha de Nacimiento:</label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <Form.Group controlId="formGender">
                                <div className="form-floating">
                                    <Form.Control
                                        as="select"
                                        name="gender"
                                        onChange={handleChange}
                                        value={formData.gender || ""}
                                        
                                    >
                                        <option value="">Selecciona un género</option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Femenino</option>
                                    </Form.Control>
                                    <label htmlFor="gender">Género:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formCurrentAntiquity">
                                <div className="form-floating">
                                    <Form.Control
                                        type="date"
                                        name="current_antiquity"
                                        onChange={handleChange}
                                        value={formData.current_antiquity || ""}
                                        
                                    />
                                    <label htmlFor="current_antiquity">Antigüedad Actual:</label>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formTotalAntiquity">
                                <div className="form-floating">
                                    <Form.Control
                                        type="date"
                                        name="total_antiquity"
                                        onChange={handleChange}
                                        value={formData.total_antiquity || ""}
                                        
                                    />
                                    <label htmlFor="total_antiquity">Antigüedad Total:</label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <Button variant="primary" type="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditInsured;
