import React, { useState } from 'react';
import axios from 'axios';
import { uploadFiles } from '../../api/PdfService';

const UploadFileComponent = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [link, setLink] = useState(null);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response=await uploadFiles(formData)

      
      setSuccess(response.message);
      setLink(response.link)
    } catch (err) {
      setError(err.response?.message || 'Error uploading files');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Upload Files</h1>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}, <hr/>Link: {link} </p>}
    </div>
  );
};

export default UploadFileComponent;
