import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AddIcon from '@mui/icons-material/Add';
import InsuredForm from './InsuredForm';
import CertificatesForm from './CertificatesForm';
import SubgroupForm from './SubgroupForm';
import SubgroupExcelUpload from './Excel/SubgroupExcelUpload';
import { Edit } from '@mui/icons-material';
import ModalEditPolicie from './Policies/ModalEditPolicies';

const ActionsPanel = ({ policyData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [showSubgroup, setShowSubgroup] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const [showInsured, setShowInsured] = useState(false);
    const [showExcelUpload, setShowExcelUpload] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionClick = () => {
        setIsOpen(false);
        handleTests();
    };

    const handleTests = () => {
        alert("Prueba")
    }

    const handleSubgroupOpen = () => setShowSubgroup(true);
    const handleSubgroupClose = () => { setShowSubgroup(false); }
    const handleCertificatesOpen = () => setShowCertificates(true);
    const handleCertificateClose = () => { setShowCertificates(false); }
    const handleInsuredOpen = () => setShowInsured(true);
    const handleInsuredClose = () => { setShowInsured(false); };
    const handleExcelOpen = () => setShowExcelUpload(true);
    const handleExcelClose = () => { setShowExcelUpload(false); };
    const handleEditOpen = () => setShowEdit(true);
    const handleEditClose = () => { setShowEdit(false); };

    return (
        <div>
            <Button
                ref={buttonRef}
                onClick={toggleMenu}
                variant="success"
                style={{
                    position: 'fixed',
                    top: '50px',
                    right: '20px',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    zIndex: 1030, // Por encima de otros elementos
                }}
            >
                <AddIcon />
            </Button>

            {isOpen && (
                <div
                    ref={menuRef}
                    style={{
                        position: 'fixed',
                        top: '90px',
                        right: '20px',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 12px rgba(0,0,0,0.15)',
                        borderRadius: '12px',
                        padding: '10px',
                        zIndex: 1040,
                    }}
                >
                    <Stack gap={2}>
                        <Button
                            variant="success"
                            onClick={handleSubgroupOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Subgrupo
                        </Button>
                        <Button
                            variant="warning"
                            onClick={handleCertificatesOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Certificado
                        </Button>
                        <Button
                            variant="dark"
                            onClick={handleInsuredOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Asegurado
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleExcelOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <FilePresentIcon style={{ marginRight: '5px' }} /> Cargar Datos
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleEditOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <Edit style={{ marginRight: '5px' }} /> Editar
                        </Button>
                    </Stack>
                </div>
            )}
            <Modal show={showExcelUpload} onHide={handleExcelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Asegurados # Poliza: {policyData.policy_number} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubgroupExcelUpload policy_id={policyData.id_policy} />
                    <button
                        className="btn btn-warning w-100"
                        onClick={() => window.location.href = 'https://seguwallet.grmseguros.mx/storage/files/6704484f05897_Plantilla-Carga-Certificados-07102024.xlsx'}
                    >
                        Descargar Plantilla
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleExcelClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showSubgroup && (
                    <SubgroupForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleSubgroupClose}
                    />
                )
            }
            {
                showCertificates && (
                    <CertificatesForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleCertificateClose}
                    />
                )
            }
            {
                showInsured && (
                    <InsuredForm
                        showModal={showInsured}
                        policyForeign={policyData?.id_policy}
                        policyData={policyData}
                        onClose={handleInsuredClose}
                    />
                )
            }
            {
                showEdit && (
                    <ModalEditPolicie
                        data={policyData}
                        showModal={showEdit}
                        closeModal={handleEditClose}
                    />
                )
            }
        </div>
    );
};

export default ActionsPanel;
