import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { openPdf } from '../../features/pdfs/functionsPdf';
import { pdfLogo } from '../../global';
import Accordion from 'react-bootstrap/Accordion';
import { uploadDeclaration } from '../../api/policyService';
import { Button, Form, Modal } from 'react-bootstrap';
import MainTableInsured from '../Tables/TableInsured/TableListInsured';
import { getSubgroupByPolicyId } from '../../api/SubgroupService';
import AddIcon from '@mui/icons-material/Add';
import SubgroupForm from '../SubgroupForm';
import InsuredForm from '../InsuredForm';
import CertificatesForm from '../CertificatesForm';
import SubgroupExcelUpload from '../Excel/SubgroupExcelUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ActionsPanel from '../ActionsPanel';

function PolicyDetails({ policyData }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [policyId, setPolicyId] = useState('');
    const [subgroups, setSubgroups] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showSubgroup, setShowSubgroup] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const [showInsured, setShowInsured] = useState(false);
    const [showExcelUpload, setShowExcelUpload] = useState(false);

    console.log("policyData desde policyDetails", policyData)
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const formattedDate = format(parseISO(dateString), 'dd-MMMM-yy', { locale: es });
        const [day, month, year] = formattedDate.split('-');
        const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
        return `${day}-${capitalizedMonth}-${year}`;
    };
    useEffect(() => {
        fetchSubgroupsByPolicy()
    }, []);
    const fetchSubgroupsByPolicy = async () => {
        const response = await getSubgroupByPolicyId(policyData.id_policy)
        setSubgroups(response)
    }
    const filterPdfsByCategory = (categoryKey) => {
        return policyData?.pdfs.filter((pdf) => pdf.category === categoryKey);
    };
    const handleFileChange = (event) => {

        console.log('file', event.target.files[0])
        setSelectedFile(event.target.files[0]);
    };
    const uploadPDFDeclaration = async (id, data) => {
        try {
            const response = await uploadDeclaration(policyId, selectedFile); // Pasar ID y archivo al handler
            console.log(response)
            alert('Subida Exitosa')
            closeUploadModal();
        } catch (error) {
            alert(error)
        }
    }
    const openUploadModal = (policyId) => {
        setPolicyId(policyId); // Guardar el ID del 
        setShowUploadModal(true);
    };
    const closeUploadModal = () => {
        setShowUploadModal(false);
        setSelectedFile(null);
        setPolicyId(null)
    };
    const categoryMap = {
        pdf_declaration: 'Caratula',
        pdf_endorsement: 'Endoso',
        pdf_receipt: 'Recibo',
        pdf_policy_bill: 'Factura',
        pdf_others: 'Otros',
        ...(policyData?.policy_type === 'GMM Individual' ? {
            pdf_digital_credentials: 'Credencial',
            pdf_presentation: 'Presentación'
        } : {})
    };
    const handleSubgroupOpen = () => setShowSubgroup(true);
    const handleSubgroupClose = () => { setShowSubgroup(false); window.location.reload(); }
    const handleCertificatesOpen = () => setShowCertificates(true);
    const handleCertificateClose = () => { setShowCertificates(false); window.location.reload(); }
    const handleInsuredOpen = () => setShowInsured(true);
    const handleInsuredClose = () => { setShowInsured(false); window.location.reload() };
    const handleExcelOpen = () => setShowExcelUpload(true);
    const handleExcelClose = () => { setShowExcelUpload(false); window.location.reload(); };
    return (
        <div>


            <ActionsPanel policyData={policyData} />
            <div className="d-flex mb-4">

                <div className="card shadow p-3  bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <h5 className="mb-0">Datos Generales de la Aseguradora</h5>
                        <img
                            src={policyData.insurer.logo_link}
                            alt="Logo Aseguradora"
                            style={{ width: '90px', height: 'auto' }}
                        />
                    </div>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>No. de Póliza</span> <span>{policyData?.policy_number}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Compañía</span> <span>{policyData?.insurer.commercial_name}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Clave Agente</span> <span>{policyData?.insurer.agent_key}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Ramo</span> <span>{policyData?.policy_branch}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>RFC</span> <span>{policyData?.insurer.rfc}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Nombre</span> <span>{policyData?.pool.name}</span>
                        </div>


                    </div>
                </div>
                <div className="card shadow p-3 mx-3 bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Datos generales de la Poliza</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>Numero de Poliza</span> <span>{policyData?.policy_number}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Inicio</span> <span>{formatDate(policyData?.start_date_coverage)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Vigencia</span> <span>{formatDate(policyData?.end_date_coverage)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo de Poliza</span> <span>{policyData?.policy_type}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Creado</span> <span>{formatDate(policyData?.created_at)}</span>
                        </div>
                    </div>
                </div>
                <div className="card shadow p-3 mb-3 bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Contratante</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Nombre:</span> <span >{policyData?.policy_holder?.business_name}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>RFC:</span> <span >{policyData?.policy_holder?.rfc}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Direccion:</span> <span >{policyData?.policy_holder?.fiscal_address}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Administrador de la poliza:</span> <span >{policyData?.admin_email || 'Sin Dato'} </span>
                        </div>

                    </div>
                </div>

            </div>
            {/* {policyData.policy_type == 'GMM Individual' && (
                <div className="d-flex">
                    <div className="card shadow p-3 bg-white rounded" style={{ width: '100%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                        <h5 className="mb-0">Datos de Asegurado</h5>
                        {policyData?.policy_holder}
                        <hr />
                        <div className="mb-3">
                            <div className="d-flex justify-content-between mb-2">
                                <span>Nombre</span> <span>{policyData?.policy_holder.business_name}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>RFC</span> <span>{policyData?.policy_holder.rfc}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>Direccion</span> <span>{policyData?.policy_holder.fiscal_address}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}


            {/* <div className="d-flex">
                <div className="card shadow p-3 mb-3 bg-white rounded" style={{ width: '40%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Primas</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>Prima Total: </span> <span>{Number(policyData?.total_premium)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Prima Neta: </span>
                            <span>{Number(policyData?.net_premium)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                            <span>Financiamiento: </span>
                            <span>
                                {Number(policyData?.financing || policyData?.fractional_payment_surcharge)
                                    ?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Porcentaje de Comision: </span> <span>{policyData?.commission_percentage}%</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Metodo de Pago: </span> <span>{policyData?.payment_method}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Bonus 1</span>
                            <span>{Number(policyData?.bonus1)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Bonus 2</span>
                            <span>{Number(policyData?.bonus2)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Renovable: </span> <span>{policyData?.renewable}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo de administracion: </span> <span>{policyData?.administration_type}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Derecho de Poliza:</span>
                            <span>{Number(policyData?.right_fee)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>IVA: </span>
                            <span>{Number(policyData?.iva)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo: </span>
                            <span>
                                {policyData?.type === 'LC' ? 'Líneas Comerciales' :
                                    policyData?.type === 'LP' ? 'Líneas Personales' :
                                        'Tipo Desconocido'}
                            </span>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                            <span>Moneda:</span> <span>{policyData?.currency}</span>
                        </div>

                    </div>
                </div>

            </div> */}
            <div className="d-flex">
                {policyData.type == 'LC' &&(
                    <div className="card shadow bg-white rounded" style={{ width: '40%', padding: '16px', marginRight: '10px', borderRadius: '8px' }}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item>
                            <Accordion.Header>Subgrupos</Accordion.Header>
                            <Accordion.Body>
                                <div className="mb-3" style={{ borderRadius: '8px' }}>
                                    {subgroups && (
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Numero</th>
                                                    <th>Presentacion</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subgroups.map((subgroup, index) => (
                                                    <tr key={index}> {/* Usar un índice o un ID único como key */}
                                                        <td>{subgroup.subgroup_name}</td>
                                                        <td>{subgroup.subgroup_number}</td>
                                                        <td>
                                                            {subgroup?.pdfs?.length > 0 ? (
                                                                <a href={subgroup.pdfs[0]?.public_link} target="_blank" rel="noopener noreferrer">
                                                                    <img src={pdfLogo} alt="Presentación" style={{ width: '40px', marginRight: '10px' }} />
                                                                </a>
                                                            ) : (
                                                                <span>Sin presentación</span>
                                                            )}

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                )}

                <div className="card shadow bg-white rounded" style={{ width: '100%', padding: '16px', borderRadius: '8px' }}>
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item >
                            <Accordion.Header>Archivos</Accordion.Header>
                            <Accordion.Body>
                                <div className="mb-3" style={{ borderRadius: '8px' }}>
                                    {policyData.pdfs?.length <= 0 && (
                                        <div>
                                            No hay Archivos para esta poliza
                                            <br/>
                                            <Button className="btn btn-secondary btn-lg me-1 mb-1" onClick={() => openUploadModal(policyData.id_policy)}>Cargar Caratula</Button>
                                        </div>

                                    )}
                                    {policyData.pdfs?.length > 0 && (
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {Object.values(categoryMap).map((category, index) => (
                                                        <th key={index}>{category}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {Object.keys(categoryMap).map((categoryKey, index) => (
                                                        <td key={index} style={{ maxWidth: '130px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {filterPdfsByCategory(categoryKey).map((pdf, pdfIndex) => (
                                                                    <div key={pdfIndex} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                                                        <a onClick={() => openPdf(pdf.public_link)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                                                            <img src={pdfLogo} alt="PDF" style={{ width: '33px', alignItems: 'center' }} />
                                                                            {/* <span style={{ fontSize: '12px', lineHeight: '1.2' }}>{pdf.file_name}</span> */}
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="card shadow bg-white rounded mt-3" style={{ width: '100%', padding: '16px', borderRadius: '8px' }}>

                {/* <Accordion defaultActiveKey="2">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Asegurados</Accordion.Header>
                        <Accordion.Body>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion> */}
                <MainTableInsured policy_id={policyData.id_policy} />

            </div>
            <Modal show={showUploadModal} onHide={closeUploadModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Subir Caratula PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Seleccionar archivo PDF:</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeUploadModal}>
                        Cancelar
                    </Button>
                    {selectedFile && (

                        <Button variant="primary" accept="application/pdf" onClick={uploadPDFDeclaration}>
                            Subir
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <Modal show={showExcelUpload} onHide={handleExcelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Asegurados1 # Poliza: {policyData.policy_number} </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <SubgroupExcelUpload policy_id={policyData.id_policy} />
                    <button
                        className="btn btn-warning w-100"
                        onClick={() => window.location.href = 'https://seguwallet.grmseguros.mx/storage/files/6704484f05897_Plantilla-Carga-Certificados-07102024.xlsx'}
                    >
                        Descargar Plantilla
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleExcelClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showSubgroup && (
                    <SubgroupForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleSubgroupClose}
                    />
                )
            }
            {
                showCertificates && (
                    <CertificatesForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleCertificateClose}
                    />
                )
            }
            {
                showInsured && (
                    <InsuredForm
                        showModal={showInsured}
                        policyForeign={policyData?.id_policy}
                        policyData={policyData}
                        onClose={handleInsuredClose}
                    />
                )
            }
        </div>
    );
}

export default PolicyDetails;
